import React from 'react'
//import Footer from '../Footer/Footer.js'
import Navbar from '../NavBar/Navbar.js'
import Stickysocial from '../StickySocials/Stickysocial.js';
import '../Styles/style-front.css'
export default function PressKit() {
    return (
        <div>
            <Navbar />
            <Stickysocial />
            <section class="inner-banner">
            </section>
            <section class="inner-pages" data-aos="fade-up">
                <div class="container how-it-works">
                    <h3>TEARMS AND CONDITIONS</h3>
                    <div class="full-hiw">
                        <p><strong>Agreement between User and www.incubatex.io</strong></p>
                        <p>Welcome to www.incubatex.io. The www.incubatex.io website (the "Site") is comprised of various web pages operated by IncubateX, LLC ("IncubateX"). www.incubatex.io is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of www.incubatex.io constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.</p>

                        <p>www.incubatex.io is a digital launchpad Site.</p>

                        <p>IncubateX is a digital assets launchpad (mainly NFTs Non-Fungible Tokens) that offers the community memberships to be able to participate in the projects launched on its website. IncubateX sells both its own created NFTs as well as third party projects'. The projects launched on the IncubateX launchpad can be either for-profit or non-profit projects, as long as they are aligned with the IncubateX community mission.</p>
                        <p><br /><strong>Rules of Using IncubateX</strong></p>

                        <p>When using IncubateX, no User is allowed to:<br /></p>
                        <ul>
                            <li>manipulate the price of a Digital Artwork in any way, including bidding on your own items, preventing bidding, or using IncubateX to conceal the economic activity.<br /></li>
                            <li>email or otherwise upload any content that (i) infringes any intellectual property or other proprietary rights of any party; (ii) you do not have a right to upload under any law or under contractual or fiduciary relationships; (iii) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (iv) poses or creates a privacy or security risk to any person; (v) constitutes unsolicited or unauthorized advertising, promotional materials, commercial activities and/or sales, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” “contests,” “sweepstakes,” or any other form of solicitation; (vi) is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s privacy, hateful racially, ethnically or otherwise objectionable; or (vii) in the sole judgment of IncubateX, is objectionable or which restricts or inhibits any other person from using or enjoying the Platform, or which may expose IncubateX or its users to any harm or liability of any type;<br /></li>
                            <li>interfere with or disrupt the Platform or servers or networks connected to the Platform, or disobey any requirements, procedures, policies or regulations of networks connected to the Platform; or<br /></li>
                            <li>violate any applicable local, state, national or international law, or any regulations having the force of law, including but not limited to the U.S. Department of Treasury’s Office of Foreign Assets Control (“OFAC”), or which would involve proceeds of any unlawful<br />activity; </li>
                            <li>impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;<br /></li><li>solicit personal information from anyone under the age of 18;<br /></li><li>harvest or collect email addresses or other contact information of other Users from the Platform by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;<br /></li><li>advertise or offer to sell or buy any goods or services for any business purpose that is not specifically authorized;<br /></li>
                            <li>further or promote any criminal activity or enterprise or provide instructional information about illegal activities, including for the purpose of concealing economic activity, laundering money, or financing terrorism;<br /></li>
                            <li>obtain or attempt to access or otherwise obtain any materials or information through any means not intentionally made available or provided for through the Platform;<br /></li>
                            <li>use any robot, spider, site search/retrieval application, or other devices to retrieve or index any portion of the Platform or the content posted on the Platform, or to collect information about its Users for any unauthorized purpose;<br /></li><li>create user accounts by automated means or under false or fraudulent pretenses;<br /></li><li>access or use the Platform for the purpose of creating a product or service that is competitive with any of our products or services.</li>
                        </ul>

                        <p><br /><strong>Electronic Communications</strong></p>

                        <p>Visiting www.incubatex.io or sending emails to IncubateX constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</p>

                        <p><br /><strong>Children Under Thirteen</strong></p>

                        <p>IncubateX does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use www.incubatex.io only with permission of a parent or guardian.</p>


                        <p><br /><strong>No Investment Advice </strong></p>

                        <p>IncubateX is not legally authorized to nor does it provide any financial or investment advice to anyone in any medium including this website. No content on the www.incubatex.io website or made by the team should be considered legal advice under any circumstance. You are hereby allowed and strongly advised to consult with your tax and/or financial professional before making any financial decisions.</p>

                        <p><br /><strong>Cancellation/Refund Policy</strong></p>

                        <p>All purchases made on the www.incubatex.io website are final and non-refundable</p>

                        <p><br /><strong>Links to Third Party Sites/Third Party Services</strong></p>

                        <p>www.incubatex.io may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of IncubateX and IncubateX is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. IncubateX is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by IncubateX of the site or any association with its operators.</p>



                        <p>Certain services made available via www.incubatex.io are delivered by third party sites and organizations. By using any product, service or functionality originating from the www.incubatex.io domain, you hereby acknowledge and consent that IncubateX may share such information and data with any third party with whom IncubateX has a contractual relationship to provide the requested product, service or functionality on behalf of www.incubatex.io users and customers.</p>


                        <p><br /><strong>No Unlawful or Prohibited Use/Intellectual Property</strong></p>


                        <p>You are granted a non-exclusive, non-transferable, revocable license to access and use www.incubatex.io strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to IncubateX that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.</p>


                        <p>All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of IncubateX or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.</p>


                        <p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. IncubateX content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of IncubateX and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of IncubateX or our licensors except as expressly authorized by these Terms.</p>


                        <p><br /><strong>Third Party Accounts</strong></p>


                        <p>You will be able to connect your IncubateX account to third party accounts. By connecting your IncubateX account to your third party account, you acknowledge and agree that you are consenting to the continuous release of information about you to others (in accordance with your privacy settings on those third party sites). If you do not want information about you to be shared in this manner, do not use this feature.</p>


                        <p><br /><strong>International Users</strong></p>


                        <p>The Service is controlled, operated and administered by IncubateX from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the IncubateX Content accessed through www.incubatex.io in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>


                        <p><br /><strong>Indemnification</strong></p>


                        <p>You agree to indemnify, defend and hold harmless IncubateX, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. IncubateX reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with IncubateX in asserting any available defenses.</p>


                        <p><br /><strong>Arbitration</strong></p>


                        <p>In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.</p>


                        <p><br /><strong>Class Action Waiver</strong></p>


                        <p>Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and IncubateX agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.</p>


                        <p><br /><strong>Liability Disclaimer</strong></p>


                        <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. INCUBATEX, LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>


                        <p>INCUBATEX, LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. INCUBATEX, LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>


                        <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL INCUBATEX, LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF INCUBATEX, LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF<br />THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>


                        <p><br /><strong>Termination/Access Restriction</strong></p>


                        <p>IncubateX reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the Territory of Puerto Rico and you hereby consent to the exclusive jurisdiction and venue of courts in Puerto Rico in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>


                        <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and IncubateX as a result of this agreement or use of the Site. IncubateX's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of IncubateX's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by IncubateX with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>


                        <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and IncubateX with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and IncubateX with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>


                        <p><br /><strong>Changes to Terms</strong></p>


                        <p>IncubateX reserves the right, in its sole discretion, to change the Terms under which www.incubatex.io is offered. The most current version of the Terms will supersede all previous versions. IncubateX encourages you to periodically review the Terms to stay informed of our updates.</p>


                        <p><br /><strong>Contact Us</strong></p>


                        <p>IncubateX welcomes your questions or comments regarding the Terms:<br />IncubateX, LLC<br />268 Ponce De Leon Ave, #1022<br />San Juan, PR 00918<br />Email Address:<br />contact@incubatex.io<br />_________________<br />Effective as of March 01, 2022</p>
                    </div>
                </div>
            </section>
            {/*<Footer />*/}
        </div>
    )
}
