import React from 'react'
import '../Styles/style-front.css'
export default function Stickysocial() {
    return (
        <div>
            {/* <!--sidebar social icon-->   */}
<div class="sticky-social" data-aos="fade-left">
    <ul class="social">
        <li><a href="https://discord.gg/4ayCtuwRMp" target="blank" class="discord"><i class="fab fa-discord" aria-hidden="true"></i></a></li>
        {/* <li><a href="https://twitter.com/IncubateX_NFT" target="blank" class="twitter"><i class="fab fa-twitter" aria-hidden="true"></i></a></li> */}
        <li><a href="https://www.instagram.com/incubatex_nft/" target="blank" class="instagram"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
        <li><a href="https://www.youtube.com/channel/UCyL7eRZ9CQAG3tGnfG2HM2g" target="blank" class="youtube"><i class="fab fa-youtube" aria-hidden="true"></i></a></li>
        <li><a href="https://www.tiktok.com/@incubatex_nft" target="blank" class="tiktok"><i class="fab fa-tiktok" aria-hidden="true"></i></a></li>
        <li><a href="https://medium.com/@IncubateX_NFT" target="blank" class="medium"><i class="fab fa-medium" aria-hidden="true"></i></a></li>
     </ul>
 </div>
        </div>
    )
}
