import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { DAppProvider } from "@usedapp/core";
import reportWebVitals from './reportWebVitals';
import UserProvider from './components/UserProvider.js';

require('react-web-vector-icons/fonts');

ReactDOM.render(

  <React.StrictMode>
      <UserProvider>
    <DAppProvider config={{}}>
    <App />
    </DAppProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
