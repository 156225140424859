import React from 'react'
import '../Styles/style-front.css';
export default function OurStory() {
    return (
        <div>
            {/* <!--story section--> */}
            <section class="story-section"  data-aos="fade-up">
                <div class="container">
                    <h3>ORIGIN STORY</h3>
                    <p>We sat in the dark,<br/>
                    contemplating what we could do.</p>
                    <p>The world was falling apart,<br/>
                    we were doing everything we could,<br/>
                    but nothing felt like it made a big enough difference</p>
 
                    <p>but then, someone had an idea…<br/>
                    “What if we become the channel? What if we leverage the power of the collective<br/> 
                    to support visionary ideas that make real change in the world?"</p>
 
                    <p>This ignited the fire in us & was the catalyst for IncubateX.</p>
 
                    <p>We are the misfits, the mystics, the rebels, the artists, the dreamers –<br/>
                        and we are tired of the status quo</p>
 
<p>So we are launching a force for good in the world.</p>
 
<p>And so begins our journey…</p>
                </div>
            </section>
        </div>
    )
}
