import React from 'react'
// import '../Styles/Responsive.css'
import '../Styles/style-front.css';
import lineimg from '../../assets/images/line_img.png'
export default function RoadMap() {
	return (
		<div>
			<section class="roadmap-section" id="roadmap"  data-aos="fade-up">
				<div class="container">
					<h3>IncubateX Roadmap</h3>
					<div class="roadmap-box">
						<div class="road-content">
							<div class="road-data1 data1" data-aos="fade-right">
								<h6>1</h6>
								<h4>Building Our Tribe</h4>
								<p>Call to action for warriors of the light, raising awareness, website launch.</p>
								<span></span>
							</div>
							<div class="road-data2 data2" data-aos="fade-left">
								<h6>2</h6>
								<h4>Ignite!</h4>
								<p>Launch of the IncubateX Membership NFTs (Presale/ Public Sale)</p>
								<span></span>
							</div>
						</div>
						<div class="road-content">
							<div class="road-data1 data3" data-aos="fade-right">
								<h6>3</h6>
								<h4>Fireworks!</h4>
								<p>We are ready to launch our incubated projects. Incubated projects start going live! Each project will be announced before it becomes available to backers. </p>
								<span></span>
							</div>
							<div class="road-data2 data4" data-aos="fade-left">
							<	h6>4</h6>
								<h4>Lightning in a Bottle</h4>
								<p>A marketplace that give you access to exclusive merch</p>
								<span></span>
							</div>
						</div>
						<div class="road-content">
							<div class="road-data1 data5" data-aos="fade-right">
								<h6>5</h6>
								<h4>Portal Into the Metaverse</h4>
								<p>IncubateX joins the metaverse! With a dedicated virtual community, we’ll be hosting events, and releasing a brand of metaverse wearables, too!</p>
								<span></span>
							</div>
							<div class="road-data2 data6" data-aos="fade-left">
								<h6>6</h6>
								<h4>Tokens of Appreciation</h4>
								<p>IncubateX token announcement & launch. More information to be announced closer to the time of launch.</p>
								<span></span>
							</div>
						</div>
					</div>
				</div>
</section>
</div>
    )
}
